<i18n src="@/locales/dental_care_refund.json" />
<i18n src="@/locales/articles-card-content.json" />

<template>
  <div class="dental-care-refound">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <p>
          {{ $t('article_p1') }}
        </p>
      </ImanHomeSection>
    </section>
    <section class="section">
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_1') }}
        </h3>
        <div v-html="$t('article_p2')" />
      </ImanArticle>
      <ImanArticle>
        <h3 class="has-text-primary is-size-4">
          {{ $t('h3_title_2') }}
        </h3>
        <div v-html="$t('article_p3')" />
      </ImanArticle>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[1]">
        <p>
          {{ $t('article_p4') }}
        </p>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_3') }}</ImanH2>
    <section class="columns is-centered background-section">
      <div class="column is-8">
        <ImanCollapsableCard
          :title="$t('collapse_title_1')"
        >
          <div v-html="$t('collapse_p1')" />
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_2')"
        >
          <div v-html="$t('collapse_p2')" />
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_3')"
        >
          <div v-html="$t('collapse_p3')" />
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_4')"
        >
          <div v-html="$t('collapse_p4')" />
        </ImanCollapsableCard>
      </div>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
  </div>
</template>

<script>
  import ImanArticleSection from "@/components/ImanArticleSection";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";

  export default {
    name: 'DentalCareRefund',
    components: {
      ImanArticleSection
    },
    mixins: [
      articlesCardContentFilterMixin,
      metaMixin
    ],
    data() {
      return {
        articlesCardContentId: [2241, 2111, 64, 810],
        publicPath: process.env.BASE_URL,
        mainBackgroundClass: 'none-background',
        img: [
          {
            path: 'useful/dental-care-refund/remboursement-soins-dentaire-securite-sociale.jpg',
            position: 'right'
          },
          {
            path: 'useful/dental-care-refund/mutuelle-remboursement-soins-dentaires.jpg',
            position: 'right'
          }
        ]
      }
    },
    computed: {
      cardContentFirstSet () {
        return cardContentDentalCare.filter(content => content.id < 4)
      },
      cardContentSecondSet () {
        return cardContentDentalCare.filter(content => content.id > 3)
      },
      openGraphImgPath () {
        return 'https://anveli.dental' + require('../../../assets/img/thumbnail/MINIATURE_5.2.png')
      }
    },
    created() {
      window.addEventListener('scroll', this.whenScroll)
    },
    destroyed() {
      window.removeEventListener('scroll', this.whenScroll)
    },
    methods: {
      whenScroll() {
        if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
          this.mainBackgroundClass = 'none-background'
        }
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
          this.mainBackgroundClass = 'fixed-background'
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .header-background {
@media only screen and (min-width: 768px) {
  /* tablet et ordinateurs */
  background-image: url("../../../assets/img/useful/dental-care-refund/H1-remboursement-soins-dentaires.jpg");
}
@media only screen and (max-width: 767px) {
  /* smartphone en mode paysage */
  background-image: url("../../../assets/img/useful/dental-care-refund/H1-remboursement-soins-dentaires.jpg");
}
@media only screen and (max-width: 767px) and (orientation: portrait) {
  /* smartphone en mode portait */
  background-image: url("../../../assets/img/useful/dental-care-refund/H1-mobile-implant-dentaire.jpg");
}
}
</style>
